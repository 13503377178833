body {
  color: $grey-dark;
}

a {
  color: $grey-medium;
  &:hover {
    color: $blue;
  }
  &:active {
    color: $blue;
  }
}
