#links-bar {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1010;
  background-color: white;
  padding: 2px 5px;
  font-size: $small;
  opacity: 0.6;

  &:hover {
    opacity: 0.8;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none outside none;
  }

  li {
    display: inline-block;
    vertical-align: middle;

    .fa:before {
      margin: 6px;
    }
  }
}
