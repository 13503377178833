@use "~/sass/variables" as v;

.jgnControlButton {

  img {
    width: 24px;
    height: 24px;
    margin: 2px;
  }

  &.ZoomIn {
    top: v.$buttons-top;
    left: v.$buttons-left;
    border-radius: 2px 2px 0 0;
  }

  &.ZoomExtent {
    top: v.$buttons-top + 34px;
    left: v.$buttons-left;
    border-radius: 0;
  }

  &.ZoomOut {
    top: v.$buttons-top + 2 * 34px;
    left: v.$buttons-left;
    border-radius: 0 0 2px 2px;
  }

  &.PrintCenter {
    top: v.$buttons-top + 3 * 34px + 8px;
    left: v.$buttons-left;
  }

  &.ScreenCenter {
    top: v.$buttons-top + 4 * 34px + 2 * 8px;
    left: v.$buttons-left;
  }
}
