.element-invisible {
  clip: rect(1px, 1px, 1px, 1px);
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  position: absolute !important;
}

.no-print {
  @media print {
    display: none;
  }
}

.print-tight {
  @media print {
    border: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    min-width: 0 !important;
    width: auto !important;
    height: auto !important;
  }
}

.no-screen {
  @media screen {
    display: none;
  }
}
