// Font size
$medium: 14px;
$small: 13px;

// Colors
$grey-light: #F5F5F5;
$grey-medium: #666666;
$grey-dark: #3B3B3B;
$blue: #0066BB;
$blue-light: #4D90FE;

// Layout
$geocoder-left: 20px;
$geocoder-top: 20px;

$buttons-left: 30px;
$buttons-top: 70px;

$layerswitcher-right: 15px;
$layerswitcher-top: 30px;

$track-right: 195px;
$track-top: $layerswitcher-top;

$panel-right: 15px;
$panel-top: $layerswitcher-top + 20px;
$triangle-height: 16px;
$triangle-width: 20px;
