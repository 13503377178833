.jgnBasicControl {
  background-color: white;
  border: 1px solid white;
  &:focus {
    border-color: #0066BB;
  }
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 4px;
  opacity: 0.85;
  &:hover {
    opacity: 1;
  }
}

.jgnButton {
  background-color: $blue;
  color: white;
  padding: 2px;
  border: 2px solid white;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.3) 1px 1px 4px;
  cursor: pointer;
  opacity: 0.85;

  img {
    opacity: 0.9;
  }

  &:hover img {
    opacity: 1;
  }
}

.jgnPanel {
  padding: 5px;
  margin: 0;
  background-color: white;
  border-radius: 5px 5px 5px 5px;
  box-shadow: rgba(0, 0, 0, 0.6) 2px 2px 4px;
}
