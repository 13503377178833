@use "~/sass/variables" as v;

.JGNControlGeocoder {
  top: v.$geocoder-top;
  left: v.$geocoder-left;

  .geocoder-address {
    width: 268px;
    border: 0;
    padding: 6px 24px 6px 8px;
    &.example {
      color: v.$grey-medium;
    }
  }

  .geocoder-submit {
    position: absolute;
    height: 16px;
    width: 16px;
    right: 7px;
    top: 7px;
    background: url("../../img/search-small.png") no-repeat scroll left top transparent;
    border: 0 none;
  }
}
