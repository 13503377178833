@use "~/sass/mixins" as m;
@use "~/sass/variables" as v;

.JGNControlBaseLayerSwitcher {
  top: v.$layerswitcher-top;
  right: v.$layerswitcher-right;
  font-size: v.$medium;

  .baseLayerSelect {}

  .selectBox {
    right: 0;
    width: 160px;

    &.expanded {
      img {
        opacity: 1;
      }
    }

    img {
      float: left;
      margin: 4px 5px 4px 4px;
      width: 16px;
      height: 16px;
    }

    div {
      line-height: 16px;
      margin: 4px;
    }
  }

  .jgnPanel {
    @include m.map-panel(v.$layerswitcher-right, 164px);
    padding: 8px;
  }

  .dropDown {
    @include m.ul-layout(152px, 102px, 4, 6px, 6px);

    img {
      height: 100px;
      width: 150px;
      border: solid 1px v.$blue;
    }

    span {
      position: absolute;
      display: block;
      left: 1px;
      right: 1px;
      bottom: 1px;
      padding: 10px 0;
      margin: 0 auto;
      background-color: white;
      color: v.$blue;
      opacity: 0.7;
      font-weight: bold;
    }

    li {
      cursor: pointer;

      &:hover, &.selected {

        img {
          border-color: v.$blue-light;
        }

        span {
          opacity: 0.9;
        }
      }
    }

  }

}
