.buymeacoffee {
  overflow: hidden;
  width: 100%;
  margin-top: 24px;
  display: flex;
  align-items: center;

  background-color: #aaa;
  color: white !important;

  img {
    height: 36px;
    margin: 8px;
  }
}
