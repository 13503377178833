.button-flat {
  text-align: center;

  button {
    background-color: $blue;
    border: 0;
    color: white;
    padding: 0.3em 1em;
    cursor: pointer;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }

    &:active {
      background-image: linear-gradient(center top, $blue-light, $blue);
    }
  }
}

.button-flat-light {
  button {
    background: none;
    border: 0;
    color: $grey-medium;
    cursor: pointer;
    padding: 0;

    &:hover {
      color: $blue;
      text-decoration: underline;
    }
  }
}
