body {
  margin: 0;
  padding: 0;
}

#main {

}

#left-column {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: $sidebar-width;
  background-color: $grey-light;
  box-shadow: rgba(black, 0.4) 0 0 4px;
  z-index: 9000;
}

#content {
  position: fixed;
  top: 0;
  bottom: 0;
  left: $sidebar-width;
  right: 0;

  .section {
    height: 100%;
    width: 100%;
  }
}
