@use 'sass:math';
@use "~/sass/variables" as v;

@mixin map-panel($button-right, $button-width) {
  position: absolute;
  top: v.$panel-top;
  right: v.$panel-right - $button-right;

  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: v.$triangle-height solid white;
    border-left: v.$triangle-width solid transparent;
    border-right: v.$triangle-width solid transparent;
    top: - v.$triangle-height;
    right: ($button-right - v.$panel-right) + (math.div($button-width, 2) - v.$triangle-width);
  }
}

@mixin ul-layout($width, $height, $col, $margin-h, $margin-v) {
  list-style: none;
  width: $col * ($width + 2 * $margin-h);
  margin: 0;
  padding: 0;

  li {
    position: relative;
    width: $width;
    height: $height;
    margin: $margin-v $margin-h;
    display: inline-block;
    text-align: center;
  }
}
