@use "~/sass/variables" as v;

/**
 * Formulaires de configuration du format
 */
#settings-panel {
  font-size: $medium;
}

#settings-header {
  background-color: $blue;
  color: white;
  border-bottom: 1px solid $blue-light;
  height: 4em;

  .section {
    padding: 16px 8px;
  }

  h2 {
    margin: 0;
  }
}

#settings-options {
  /* make it scrollable */
  position: absolute;
  top: 4em;
  bottom: 0;
  margin-top: 1px;
  width: 100%;
  overflow-y: auto;

  .section {
    padding: 0 8px;
  }

  .block-option {
    line-height: 1.5em;
    border-bottom: 1px solid $blue;
    padding-bottom: 0.7em;
    padding-top: 0.7em;

    &.first {}

    &.last {
      border-bottom: none;
      padding-bottom: 0;
    }

    .right-column {
      margin-left: 10px;
    }
  }

  h4 {
    margin: 0 0 0.2em;
    font-size: 100%;
    font-weight: normal;
  }

  .custom-options {
    &, input {
     font-size: $small;
    }
  }

  .hidden-by-default {
    display: none;
  }

  td {
    padding: 0 3px;
  }

  select {
    min-width: 5em;
  }

  input {
    height: 1em;
    width: 2em;
    font-size: $small !important;
  }

  ul.buttons {
    margin: 0;
    padding: 0;
    list-style: none outside none;

    .fa:before {
      margin: 6px;
      display: block;
      float: left;
    }
  }
}

#smartPrint-button {
  margin-top: 1em;
}

#paper-lock {
  cursor: help;
}
