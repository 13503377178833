body {
  color: #3B3B3B;
}

a {
  color: #666666;
}
a:hover {
  color: #0066BB;
}
a:active {
  color: #0066BB;
}

body {
  font-family: Arial, "Helvetica Neue", sans-serif;
}

a:link, a:visited, a:hover, a:active, a:focus {
  text-decoration: none;
}

.element-invisible {
  clip: rect(1px, 1px, 1px, 1px);
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  position: absolute !important;
}

@media print {
  .no-print {
    display: none;
  }
}

@media print {
  .print-tight {
    border: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    min-width: 0 !important;
    width: auto !important;
    height: auto !important;
  }
}

@media screen {
  .no-screen {
    display: none;
  }
}

.button-flat {
  text-align: center;
}
.button-flat button {
  background-color: #0066BB;
  border: 0;
  color: white;
  padding: 0.3em 1em;
  cursor: pointer;
  opacity: 0.8;
}
.button-flat button:hover {
  opacity: 1;
}
.button-flat button:active {
  background-image: linear-gradient(center top, #4D90FE, #0066BB);
}

.button-flat-light button {
  background: none;
  border: 0;
  color: #666666;
  cursor: pointer;
  padding: 0;
}
.button-flat-light button:hover {
  color: #0066BB;
  text-decoration: underline;
}

body {
  margin: 0;
  padding: 0;
}

#left-column {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 175px;
  background-color: #F5F5F5;
  box-shadow: rgba(0, 0, 0, 0.4) 0 0 4px;
  z-index: 9000;
}

#content {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 175px;
  right: 0;
}
#content .section {
  height: 100%;
  width: 100%;
}

/**
 * Formulaires de configuration du format
 */
#settings-panel {
  font-size: 14px;
}

#settings-header {
  background-color: #0066BB;
  color: white;
  border-bottom: 1px solid #4D90FE;
  height: 4em;
}
#settings-header .section {
  padding: 16px 8px;
}
#settings-header h2 {
  margin: 0;
}

#settings-options {
  /* make it scrollable */
  position: absolute;
  top: 4em;
  bottom: 0;
  margin-top: 1px;
  width: 100%;
  overflow-y: auto;
}
#settings-options .section {
  padding: 0 8px;
}
#settings-options .block-option {
  line-height: 1.5em;
  border-bottom: 1px solid #0066BB;
  padding-bottom: 0.7em;
  padding-top: 0.7em;
}
#settings-options .block-option.last {
  border-bottom: none;
  padding-bottom: 0;
}
#settings-options .block-option .right-column {
  margin-left: 10px;
}
#settings-options h4 {
  margin: 0 0 0.2em;
  font-size: 100%;
  font-weight: normal;
}
#settings-options .custom-options, #settings-options .custom-options input {
  font-size: 13px;
}
#settings-options .hidden-by-default {
  display: none;
}
#settings-options td {
  padding: 0 3px;
}
#settings-options select {
  min-width: 5em;
}
#settings-options input {
  height: 1em;
  width: 2em;
  font-size: 13px !important;
}
#settings-options ul.buttons {
  margin: 0;
  padding: 0;
  list-style: none outside none;
}
#settings-options ul.buttons .fa:before {
  margin: 6px;
  display: block;
  float: left;
}

#smartPrint-button {
  margin-top: 1em;
}

#paper-lock {
  cursor: help;
}

#links-bar {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1010;
  background-color: white;
  padding: 2px 5px;
  font-size: 13px;
  opacity: 0.6;
}
#links-bar:hover {
  opacity: 0.8;
}
#links-bar ul {
  margin: 0;
  padding: 0;
  list-style: none outside none;
}
#links-bar li {
  display: inline-block;
  vertical-align: middle;
}
#links-bar li .fa:before {
  margin: 6px;
}

.olMap .olControlAttribution {
  left: 3px;
  bottom: 0px;
}
.olMap .olControlAttribution img {
  margin: 5px 0;
  opacity: 0.5;
}
.olMap .olControlAttribution img:hover {
  opacity: 0.7;
}

.jgnBasicControl {
  background-color: white;
  border: 1px solid white;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 4px;
  opacity: 0.85;
}
.jgnBasicControl:focus {
  border-color: #0066BB;
}
.jgnBasicControl:hover {
  opacity: 1;
}

.jgnButton {
  background-color: #0066BB;
  color: white;
  padding: 2px;
  border: 2px solid white;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.3) 1px 1px 4px;
  cursor: pointer;
  opacity: 0.85;
}
.jgnButton img {
  opacity: 0.9;
}
.jgnButton:hover img {
  opacity: 1;
}

.jgnPanel {
  padding: 5px;
  margin: 0;
  background-color: white;
  border-radius: 5px 5px 5px 5px;
  box-shadow: rgba(0, 0, 0, 0.6) 2px 2px 4px;
}

.buymeacoffee {
  overflow: hidden;
  width: 100%;
  margin-top: 24px;
  display: flex;
  align-items: center;
  background-color: #aaa;
  color: white !important;
}
.buymeacoffee img {
  height: 36px;
  margin: 8px;
}