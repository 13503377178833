@use "~/sass/mixins" as m;
@use "~/sass/variables" as v;

.JGNControlTrack {
  top: v.$track-top;
  right: v.$track-right;
  font-size: v.$medium;

  .importButton {
    right: 0;

    &.expanded {
      img {
        opacity: 1;
      }
    }

    img {
      width: 16px;
      height: 16px;
      margin: 4px;
    }
  }

  .importPanel {
    @include m.map-panel(v.$track-right, 30px);
    padding: 0.5em 1.5em;
    width: 475px;

    h3 {
      font-weight: normal;
      padding: 0.5em 0 0.8em 0;
    }

    .form-line {
      line-height: 22px;
      border-bottom: 1px solid #DDDDDD;
      padding-bottom: 10px;
      padding-top: 10px;

      &.first {
        padding-top: 0;
      }

      &.last {
        border-bottom: none;
        padding-bottom: 0;
      }

      &.buttons {
        text-align: right;

        & > div {
          display: inline-block;
        }

        .button-separator {
          margin-left: 1em;
          margin-right: 1em;
        }
      }

      .right-column {
        display: inline-block;
      }
    }

    h4 {
      margin: 0;
      display: inline-block;
      width: 90px;
      font-size: 100%;
      font-weight: normal;
      vertical-align: top;
      color: v.$grey-medium;
    }
  }
}
