.olMap .olControlAttribution {
  left: 3px;
  bottom: 0px;

  img {
    margin: 5px 0;
    opacity: 0.5;

    &:hover {
      opacity: 0.7;
    }
  }
}
